import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Drawer,
  Typography,
  Button,
  useMediaQuery,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack
} from "@mui/material";
import styled from "@emotion/styled";
import logo from "../assets/images/Gaco-Logo-breit.svg";
import Login from "./Login";
import { sendToBackend } from "../helper/websocketHelper";
import HeaderMenuItem from "./HeaderMenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "../theme";
import { ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Bidding from "./Bidding";
import NoMembershipBanner from "./modules/NoMembershipBanner";
import Search from "./Search";

function Header({ menu, userMenu, loggedIn, bidding, staticTexts, noMembership, modifyUsers, searchAvailable, searchFilters }) {
  const [loginDrawerOpen, setLoginDrawerOpen] = useState(false);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(0);
  const [menuOffset, setMenuOffset] = useState(0);
  const tablet = useMediaQuery(theme.breakpoints.up('lg'));

  const handleLink = () => {
    setBurgerMenuOpen(0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (bidding?.value?.bid && bidding?.value?.bid?.euros === null) {
      setBurgerMenuOpen(2);
    } else if ((loggedIn && burgerMenuOpen === 1) || (!loggedIn && burgerMenuOpen === 2)) {
      setBurgerMenuOpen(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidding, loggedIn]);

  // Styling
  const Wrapper = styled(Container)(() => ({
    position: "sticky",
    top: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.lightgreen,
    zIndex: 5,
    padding: "20px 30px 10px",
    paddingTop: `${menuOffset}px`,
    borderBottom: `${theme.palette.darkgreen} double 3px`,
    margin: "0 auto 50px",
    transition: "padding-top ease-in-out 500ms",

    [theme.breakpoints.up("sm")]: {
      background: theme.palette.white,
      width: "100%",
      paddingTop: `${menuOffset}px`,
    },

    [theme.breakpoints.up("lg")]: {
      margin: `${menuOffset > 0 ? "0 auto" : "100px auto"}`,
      paddingTop: `${menuOffset}px`,
    },
  }));

  const Logo = styled(Box)(() => ({
    background: `url(${logo})`,
    aspectRatio: "300 / 110",
    height: `${burgerMenuOpen === 1 ? "15vh" : "35px"}`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    maxWidth: "100vw",

    [theme.breakpoints.up("md")]: {
      marginTop: 10,
      height: 70,
    },
  }));
  // End of Styling

  return (
    <>
      {noMembership && <NoMembershipBanner text={noMembership.value} />}
      {bidding && (
        <Bidding
          {...{ menuOffset }}
          {...{ setMenuOffset }}
          {...{ burgerMenuOpen }}
          {...{ setBurgerMenuOpen }}
          {...{ bidding }}
        />
      )}
      <Wrapper className={`header ${bidding.value && "header--with_bidding"}`}>
        <Link to="/">
          <Logo />
        </Link>
        {tablet ? (
          <MenuContainer>
            {menu
              ? menu.map((main, index) => {
                return <HeaderMenuItem main={main} key={index} />
              })
              : "nope"}
              {searchAvailable && <Search searchFilters={searchFilters} loggedIn={loggedIn}/>}
            {!loggedIn ? (
              <>
                <LoginButton
                  variant="outlined"
                  onClick={() => setLoginDrawerOpen(true)}
                >
                  {staticTexts.login}
                </LoginButton>
                <Drawer
                  anchor="right"
                  open={loginDrawerOpen}
                  onClose={() => setLoginDrawerOpen(false)}
                >
                  <Login staticTexts={staticTexts} closeDrawer={() => setLoginDrawerOpen(false)} modifyUsers={modifyUsers} />
                </Drawer>
              </>
            ) : (
              <HeaderMenuItem main={userMenu} userMenu={true} setLoginDrawerOpen={setLoginDrawerOpen} staticTexts={staticTexts}/>
            )}
          </MenuContainer>
        ) : (
          <>
          <Stack direction="row">
            {searchAvailable && <Search searchFilters={searchFilters} loggedIn={loggedIn}/>}
            <IconButton size="small" onClick={() => setBurgerMenuOpen(1)}>
              <MenuIcon fontSize="large" color="primary" />
            </IconButton>
          </Stack>
            <Drawer
              anchor="top"
              open={burgerMenuOpen === 1}
              onClose={() => setBurgerMenuOpen(0)}
            >
              <MenuContainer>
                <Logo className="logo" sx={{ margin: "0 auto" }} />
                <Box>
                  {menu &&
                    menu.map((main, index) => {
                      return (
                        <div key={index}>
                          {main.entries.length === 1 ? (
                            <SingleMenuItem
                              to={main.entries[0].pathname}
                              key={index}
                              onClick={handleLink}
                            >
                              {main.entries[0].name}
                            </SingleMenuItem>
                          ) : (
                            <StyledAccordion key={index}>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">
                                  {main.name}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {main.entries.map((sub, index) => {
                                  return (
                                    <StyledLink
                                      to={sub.pathname}
                                      key={index}
                                      onClick={handleLink}
                                    >
                                      {sub.name}
                                    </StyledLink>
                                  );
                                })}
                              </AccordionDetails>
                            </StyledAccordion>
                          )}
                        </div>
                      );
                    })}
                </Box>
                {!loggedIn ? (
                  <>
                    <LoginButton
                      variant="outlined"
                      onClick={() => setLoginDrawerOpen(true)}
                    >
                      {staticTexts.login}
                    </LoginButton>
                    <Drawer
                      anchor="right"
                      open={loginDrawerOpen}
                      onClose={() => setLoginDrawerOpen(false)}
                    >
                  <Login staticTexts={staticTexts} closeDrawer={() => setLoginDrawerOpen(false)} modifyUsers={modifyUsers} />
                    </Drawer>
                  </>
                ) : (
                  <>
                  <UserMenuButton>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      {userMenu && userMenu.name}
                    </AccordionSummary>
                    <AccordionDetails>
                      {userMenu && userMenu.entries.map((sub, index) => {
                        return (
                          <StyledLink
                            to={sub.pathname}
                            key={index}
                            onClick={handleLink}
                          >
                            {sub.name}
                          </StyledLink>
                        );
                      })}
                    </AccordionDetails>
                </UserMenuButton>
                <Stack alignItems="center">
                <LogoutButton 
                  variant="contained"
                  onClick={() => {
                    sendToBackend("logout");
                    setLoginDrawerOpen(false);
                  }}
                  >
                  {staticTexts.logout}
                </LogoutButton>
                </Stack>
              </>
                )}
              </MenuContainer>
            </Drawer>
          </>
        )}
      </Wrapper>
    </>
  );
}

// more Styles

const MenuContainer = styled(Box)(() => ({
  padding: "30px 0",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100vw",
  margin: "0",
  gap: 20,
  background: theme.palette.lightgreen,
  [theme.breakpoints.up("lg")]: {
    justifyContent: "flex-end",
    alignItems: "center",
    background: theme.palette.white,
    width: "75%",
    flexDirection: "row",
    padding: 0,
    margin: 0,
  },

  '& .MuiButton-root': {
    whiteSpace: 'nowrap',
  }
}));

const LoginButton = styled(Button)(() => ({
  margin: "0",
  width: 150,
  border: `${theme.palette.lightgreen} 2px solid`,
  borderRadius: 30,
  background: theme.palette.white,
  transition: "background 500ms ease-in-out, transform 150ms ease-in-out",
  "&:hover": {
    background: theme.palette.lightgreen,
    transition: "background 500ms ease-in-out, transform 150ms ease-in-out",
  }
}));

const LogoutButton = styled(Button)(() => ({
  margin: "0",
  width: "fit-content",
  borderRadius: 30,
  border: "none",
  transition: "transform 150ms ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 150ms ease-in-out",
    border: "none",
  }
}));

const StyledLink = styled(Link)(() => ({
  display: "block",
  textDecoration: "none",
  color: theme.palette.black,
  marginBottom: 5,
  padding: "10px 0 10px 30px",
  "&:nth-of-type(odd)": {
    background: `${theme.palette.lightgreen}44`,
  },
  [theme.breakpoints.up("md")]: {
    transition: "background 150ms ease-in-out",
    "&:hover": {
      background: `${theme.palette.stone}55`,
      transition: "background 150ms ease-in-out",
    },
    padding: 20,
  }, 
}));

const SingleMenuItem = styled(Link)(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: "1.0625rem",
  display: "block",
  textDecoration: "none",
  color: theme.palette.black,
  marginBottom: 5,
  textAlign: "left",
  padding: "15px 0 15px 37px",
  background: theme.palette.white,
  margin: "2px 20px",
}));

const StyledAccordion = styled(Accordion)(() => ({
  padding: "0 20px",
  margin: "2px 20px",
}));

const UserMenuButton = styled(Accordion)(() => ({
  borderRadius: "5px",
  padding: "0 20px",
  margin: "2px 20px",
}));

export default Header;
