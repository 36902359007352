import { useState } from "react";
import { Button, Menu, Stack } from "@mui/material";
import theme from "../theme";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AccountBox } from "@mui/icons-material";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { sendToBackend } from "../helper/websocketHelper";

function HeaderMenuItem({ main, userMenu, setLoginDrawerOpen, staticTexts }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {main.entries.length === 1 ? (
        <MenuLink to={main.entries[0].pathname}>
          {main.entries[0].name}
        </MenuLink>
      ) : (
        <>
          <MenuOpenButton endIcon={userMenu ? null : <ArrowDropDownIcon />} onClick={handleClick}>
            {userMenu ? <AccountBox /> : main.name}
          </MenuOpenButton>
          <Menu
            sx={{overflow: "hidden"}}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PopoverClasses={{
              background: "green",
            }}
          >
            {[
              ...main.entries.map((sub, index) => (
                <StyledLink to={sub.pathname} key={index} onClick={handleClose}>
                  {sub.name}
                </StyledLink>
              )),
              userMenu && (
                <Stack key="logout" justifyContent="center" alignItems="center">
                  <LogoutButton 
                    variant="contained"
                    onClick={() => {
                      sendToBackend("logout");
                      setLoginDrawerOpen(false);
                    }}
                  >
                    {staticTexts.logout}
                  </LogoutButton>
                </Stack>
              )
            ]}
          </Menu>
        </>
      )}
    </>
  );
}

export default HeaderMenuItem;

const StyledLink = styled(Link)(() => ({
  display: "block",
  textDecoration: "none",
  color: theme.palette.black,
  padding: 20,
  transition: "background 150ms ease-in-out",
  "&:hover": {
    background: `${theme.palette.stone}55`,
    transition: "background 150ms ease-in-out",
  },
}));

const MenuOpenButton = styled(Button)(() => ({
  color: theme.palette.black,
  textTransform: "none",
  fontSize: 18,
  fontWeight: 700,
  transition: "background 500ms ease-in-out",
  "&:hover": {
    background: `${theme.palette.stone}22`,
    transition: "background 500ms ease-in-out",
  },
}));

const MenuLink = styled(Link)(() => ({
  color: theme.palette.black,
  textDecoration: "none",
  padding: "6px 8px",
  textAlign: "center",
  fontSize: 18,
  lineHeight: "30px",
  fontWeight: 700,
  transition: "background 500ms ease-in-out",
  "&:hover": {
    background: `${theme.palette.stone}22`,
    transition: "background 500ms ease-in-out",
  },
}));

const LogoutButton = styled(Button)(() => ({
  margin: 0,
  padding: "10px 0",
  width: "100%",
  border: "none",
  transition: "transform 150ms ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 150ms ease-in-out",
    border: "none",
  }
}));