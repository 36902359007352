import { useState, useEffect } from 'react';
import { Typography, Box, Button, Pagination } from '@mui/material';
import styled from '@emotion/styled';
import theme from "../../theme";
import ImageModal from './ImageModal';
import { MilkdownProvider } from '@milkdown/react';
import Editor from './Editor';

function Comments({ data }) {

  // comments and pagination
  const commentsPerPage = 5;
  const [slicedComments, setSlicedComments] = useState([]);
  useEffect(() => {
    data.length < commentsPerPage
      ? setSlicedComments(data)
      : setSlicedComments(data.slice(1, (commentsPerPage + 1)))
  }, [document])

  const handlePagination = (page) => {
    const first = (page - 1) * commentsPerPage;
    const last = page * commentsPerPage;
    setSlicedComments(data.slice(first, last));
  }

  return (
    <Box>
      <CommentsHeadline variant='h4'>Kommentare:</CommentsHeadline>
      {slicedComments.map((comment, index) => (
        <Comment key={index}>
          <Typography sx={{ padding: "10px 30px" }}>
            {comment.created_by && `Von ${comment.created_by}`} {comment.created_at && `am ${new Date(comment.created_at).toLocaleDateString('de-DE', {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}`}:
          </Typography>
          <InnerComment>
            <CommentText>
              <MilkdownProvider>
                <Editor body={comment.body} />
              </MilkdownProvider>
            </CommentText>
            {comment.images &&
              comment.images.map((image, index) => {
                return <ImageModal key={index} image={image} index={index} />
              })
            }
          </InnerComment>
        </Comment>
      ))}
      {data.length > 5 &&
        <StyledPagination
          count={Math.ceil(data.length / 5)}
          onChange={(e, value) => handlePagination(value)}
        />
      }
      <Button variant="contained" color='secondary' sx={{ margin: "20px" }}>Kommentar verfassen</Button>
    </Box>
  )
}

export default Comments


// Styling
const Comment = styled(Box)(() => ({
  padding: "20px 0 0 0",
  margin: "0",
  background: `${theme.palette.stone}77`,
  "&:nth-of-type(odd)": {
    background: `${theme.palette.stone}33`,
  },
  [theme.breakpoints.up('md')]: {
    padding: 20,
  },
}));

const CommentsHeadline = styled(Typography)(() => ({
  color: theme.palette.white,
  fontSize: 30,
  fontWeight: 800,
  background: theme.palette.stone,
  padding: 20,
}));

const InnerComment = styled(Box)(() => ({
  display: "flex",
  padding: "0 30px",
}));

const CommentText = styled(Typography)(() => ({
  flexGrow: 1,
  marginBottom: 20,
  textAlign: "left",
  fontFamily: "lora",
}));

const StyledPagination = styled(Pagination)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "20px auto"
}));