import React, { useEffect } from "react";
import { Typography, Button, Stack, Chip, FormGroup, Switch, FormControlLabel } from "@mui/material";
import { DataGridPro, GridColumns, GridToolbar, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { PieChart } from "./PieChart";
import { sendToBackend } from "../helper/websocketHelper";
import { AdminMembershipTimeLine } from "./AdminMembership/AdminMembershipTimeLine";
import { Dayjs } from "dayjs";
import { Membership } from "../types/Membership";
import { CheckBox, Cancel } from "@mui/icons-material";
import moneyFormater from "../helper/moneyFormater"
export type AdminMembershipProps = {
  data: any
}

const basicTableModel = {
  name: true,
  surname: true,
  city: false,
  id: true,
  "id-2": true,
  start_at: true,
  end_at: true,
  status: true,
  vp_title: true,
  has_current_bid: true,
  deposit: false,
  months_of_activity: false,
  shares: false,
  sum_year: false,
  current_sum: false,
  diff: false,
  notes: false,
  transaction_count: false,
  bid_cents_for_current_year: false,
}

const fincanialTableModel = {
  id: true,
  "id-2": true,
  status: true,
  surname: true,
  name: true,
  months_of_activity: true,
  shares: true,
  sum_year: true,
  current_sum: true,
  diff: true,
  notes: true,
  vp_title: false,
  has_current_bid: false,
  deposit: true,
  start_at: false,
  end_at: false,
  city: false,
  transaction_count: true,
  bid_cents_for_current_year: true
}

export default function AdminMemberships({ data }: AdminMembershipProps) {
  const storedFilterModel = localStorage.getItem('gridFilterModel');
  const storedColumnVisibilityModel = localStorage.getItem('columnVisibilityModel');

  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>();
  const [memberships, setMemberships] = React.useState<Membership[]>([]);
  const [onlyShowChangedMemberships, setOnlyShowChangedMemberships] = React.useState<boolean>(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>(storedColumnVisibilityModel === 'finance' ? fincanialTableModel : basicTableModel);
  const [filterModel, setFilterModel] = React.useState(storedFilterModel ? JSON.parse(storedFilterModel) : { items: [] });
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    if (!data.interaction.memberships.value) return
    setMemberships(Membership.fromJsonArray(data.interaction.memberships.value))
  }, [data.interaction.memberships.value])

  const columns: GridColumns = [
    { field: "surname", headerName: "Nachname", flex: 2 },
    { field: "name", headerName: "Vorname", flex: 2 },
    { field: "city", headerName: "Wohnort", flex: 2 },
    { field: "id", headerName: "ID", flex: 1, type: "number" },
    {
      field: "start_at",
      headerName: "Startdatum",
      type: "date",
      flex: 1.5,
      valueGetter: (params) => params.value,
      renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }) : ""
    },
    {
      field: "end_at",
      headerName: "Enddatum",
      type: "date",
      flex: 1.5,
      valueGetter: (params) => params.value,
      renderCell: (params) => params.value ? new Date(params.value).toLocaleDateString('de-DE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }) : ""
    },
    {
      type: "singleSelect",
      valueOptions: [
        { label: 'Warteliste', value: 'WaitingList' },
        { label: 'Passiv', value: 'NoVegetables' },
        { label: 'Aktiv', value: 'Active' },
        { label: 'Probe', value: 'TestMembership' },
        { label: 'Team', value: 'Team' },
        { label: 'Umsonstanteil', value: 'FreeVegetables' },
        { label: 'Ausgetreten', value: 'Ausgetreten' },
      ],
      field: "subscription",
      headerName: "Status",
      flex: 1.8,
      valueGetter: (params) => {
        if (params.row.end_at && params.row.end_at.isBefore(selectedDate)) {
          return "Ausgetreten"
        }

        return params.row.subscription;
      },
      renderCell: (params) => {
        const member: Membership = params.row
        let { color, label, whiteText, opacity } = member.getLabelAndColor(selectedDate)

        return <Chip size="small" label={label} sx={{
          bgcolor: color,
          color: whiteText ? "white" : "black",
          opacity: opacity ? 0.5 : 1
        }} />
      }
    },
    {
      field: "vp_title",
      headerName: "Verteilpunkt",
      flex: 1,
      valueGetter: (props) => props.row.vp_code,
      renderCell: (props) =>
        props.row.vp_path && (
          <Link to={`/${props.row.vp_path}`}>{props.row.vp_code}</Link>
        ),
    },
    {
      field: "has_current_bid",
      headerName: "Aktuelles Gebot",
      flex: 1,
      type: "boolean",
      valueGetter: (props) => props.row.has_current_bid,
      renderCell: (props) => (
        props.row.has_current_bid ? <CheckBox color="success" /> : <Cancel color="error" />
      )
    },
    {
      field: "deposit",
      headerName: "Einlage",
      flex: 1,
      type: "number",
      valueGetter: (params) => params.row.deposit,
      renderCell: (params) => {
        // console.log("bla: " + Object.keys(params.row));
        return moneyFormater(params.row.deposit);
      }
    },
    {
      field: "months_of_activity",
      headerName: "aktive Monate",
      type: "number",
      valueGetter: (params) => params.row.active_months,
      renderCell: (params) => params.row.active_months,
    },
    {
      field: "shares",
      headerName: "Anteile",
      type: "singleSelect",
      valueOptions: [
        { label: '0,5', value: '0,5' },
        { label: '1', value: '1' },
        { label: '1,5', value: '1,5' },
        { label: '2', value: '2' },
      ],
      flex: 1,
      valueGetter: (params) => {
        switch (params.row.shares) {
          case "Half": return "0,5"
          case "One": return "1"
          case "OneAndHalf": return "1,5"
          case "Two": return "2"
        }
      },
      renderCell: (params) => {
        switch (params.row.shares) {
          case "Half": return "0,5"
          case "One": return "1"
          case "OneAndHalf": return "1,5"
          case "Two": return "2"
        }
      }
    },
    {
      field: "bid_cents_for_current_year",
      headerName: "Beitrag bis Jahresende",
      type: "number",
      flex: 1,
      valueGetter: (params) => params.row?.bid_cents_for_current_year,
      renderCell: (params) => {
        return moneyFormater(params.row?.bid_cents_for_current_year);
      }
    },
    {
      field: "transaction_count",
      headerName: "Überweisungen",
      type: "number",
      sortComparator: (v1, v2) => {
        // console.log(v1);
        return parseInt(v1) - parseInt(v2)
      },
      flex: 1,
      valueGetter: (params) => params.row?.finance?.count,
      renderCell: (params) => {
        return params.row?.finance?.count;
      }
    },
    {
      field: "current_sum",
      headerName: "bezahlte Summe",
      type: "number",
      flex: 1,
      valueGetter: (params) => params.row?.finance?.current_sum,
      renderCell: (params) => {
        return moneyFormater(params.row?.finance?.current_sum);
      }
    },
    {
      field: "diff",
      headerName: "Differenz",
      type: "number",
      flex: 1,
      valueGetter: (params) => params.row?.finance?.diff,
      renderCell: (params) => (
        <Typography color={params.row?.finance?.diff > -1 ? "black" : "error"}>{moneyFormater(params.row?.finance?.diff)}</Typography>
      )
    },
    { field: "notes", headerName: "Notizen", flex: 2, sortable: false },
    {
      field: "id-2",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (props) => (
        <Link to={`/admin_membership_${props.row.id}`}>Details</Link>
      ),
    },
  ];

  // Filtermodell im Local Storage speichern, dann anwenden.
  const handleFilterModelChange = (filterModel: any) => {
    localStorage.setItem('gridFilterModel', JSON.stringify(filterModel));
    setFilterModel(filterModel);
  };

  const filterMembershipsToChanged = (memberships: Membership[]) => {
    return memberships.filter(m => {
      if ((m.start_at && m.start_at.isSame(selectedDate)) || (m.end_at && m.end_at.isSame(selectedDate))) return true
      return false
    })
  }

  const createMembership = () => {
    sendToBackend("create_membership")
  };
  return (
    <>
      <Typography variant="h2">Übersicht Mitgliedschaften</Typography>
      <AdminMembershipTimeLine selectedDate={selectedDate} data={memberships} setSelectedDate={date => {
        setSelectedDate(date)
      }} />
      <Stack direction="row" justifyContent={"space-between"} sx={{ marginBottom: 2, pl: 2, }}>
        <FormGroup>
          <FormControlLabel control={<Switch checked={onlyShowChangedMemberships} onChange={(e, check) => {
            setOnlyShowChangedMemberships(check)
          }} />} label="Nur Veränderungen anzeigen" />
        </FormGroup>
        <Button variant={columnVisibilityModel === basicTableModel ? "contained" : "outlined"} size="small" onClick={() => {
          setColumnVisibilityModel(basicTableModel);
          localStorage.setItem('columnVisibilityModel', 'basic');
        }}>normale Ansicht</Button>
        <Button variant={columnVisibilityModel !== basicTableModel ? "contained" : "outlined"} size="small" onClick={() => {
          setColumnVisibilityModel(fincanialTableModel);
          localStorage.setItem('columnVisibilityModel', 'finance');
        }}>Finanztabelle</Button>
        <Button variant="contained" size="large" sx={{ margin: "0 0 0 auto" }} onClick={createMembership}>
          Neue Mitgliedschaft anlegen
        </Button>
      </Stack>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              }
            }}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(model) => setColumnVisibilityModel(model)}
            filterModel={filterModel}

            onFilterModelChange={handleFilterModelChange}
            rows={onlyShowChangedMemberships ? filterMembershipsToChanged(memberships) : memberships}
            sx={{ height: "500px" }}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </div>
      </div>
      <PieChart title="" data={data.interaction.stats.value} />
      <Typography variant="h6" textAlign="center">Erwartete Beitragssumme bis zum Jahresende: {moneyFormater(data.interaction.total_bid_cents_for_current_year.value)}</Typography>
    </>
  );
}