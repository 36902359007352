import { Box, Typography, Stack } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import { MilkdownProvider } from "@milkdown/react";
import Editor from "./modules/Editor";

function VP({ data }) {
  const document = data.interaction.vp.value;
  const texts = data.interaction.texts.value;

  console.log(texts)

  const Wrapper = styled(Stack)(() => ({
    padding: "0 20px",

    [theme.breakpoints.up("lg")]: {
      padding: "20px 0",
    },

    ".vp": {
      "&__infobox": {
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 20,
        margin: "0 20px 50px",

        [theme.breakpoints.up("lg")]: {
          gap: 100,
        },
      },
    },

    h4: {
      margin: "0 20px 20px",
    },
  }));
  return (
    <Wrapper className="vp">
      <Typography variant="h2">{texts.title}</Typography>
      {document.opening && (
        <Typography variant="h4">{texts.opening_hours}: {document.opening}</Typography>
      )}

      <Stack className="vp__infobox">
        <Box>
          {document.code && <Typography>{texts.code}: {document.code}</Typography>}
          {(
            <Typography>{texts.members}: {document.members_count}</Typography>
          )}
          {(
            <Typography>{texts.waiting_members}: {document.waiting_members_count}</Typography>
          )}
          {document.contact && (
            <Typography>{texts.contact}: {document.contact}</Typography>
          )}
        </Box>
        <Box>
          {document.case_storage && (
            <Typography>{texts.case_storage}: {document.case_storage}</Typography>
          )}
          {document.created_at && (
            <Typography>{texts.created_at} {new Date(document.created_at).toLocaleDateString('de-DE', {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}</Typography>
          )}
          {document.status && (
            <Typography>{texts.status}: {document.status}</Typography>
          )}
          {document.space && <Typography>{texts.space}: {document.space}</Typography>}
        </Box>
      </Stack>
      {document.body && (
        <>
          <Typography variant="h2">{texts.infos}</Typography>
          {/*
          <Box
            className="vp__infobox"
            dangerouslySetInnerHTML={{ __html: document.body }}
          ></Box>
          */}
          <MilkdownProvider>
            <Editor body={document.body} />
          </MilkdownProvider>
        </>
      )}
      {document.members.length > 0 && (
        <>
          <Typography variant="h2">{texts.members}</Typography>
          <ul>
            {document.members.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        </>
      )}
    </Wrapper>
  );
}

export default VP;
