import { useState, useEffect } from "react";
import { Typography, Box, Divider, IconButton, Snackbar } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import ImageModal from "./modules/ImageModal";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import CopyToClipboard from "react-copy-to-clipboard";
import { url } from "../helper/websocketHelper";
import Comments from "./modules/Comments";
import Editor from "./modules/Editor";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { MilkdownProvider } from "@milkdown/react";

function Event({ data }) {
  const document = data.interaction.event.value;

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const arr = locations;
    Object.values(document.location).forEach((e) => arr.push(e));
    const uniqueArray = arr.filter(function (item, pos) {
      return arr.indexOf(item) === pos;
    });
    setLocations(uniqueArray);
  }, [document, locations]);

  // Snackbar (confirm copy to clipboard)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <MainContent>
        <StyledHeadline>
          <Typography variant="h3">am {document.title}</Typography>
          <Typography variant="h6">am {document.start_at ? new Date(document.start_at).toLocaleDateString('de-DE', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) : ""}</Typography>
        </StyledHeadline>
        <DateAndButtonBox>
          {document.created_at && (
            <Typography>veröffentlicht am {document.created_at ? new Date(document.created_at).toLocaleDateString('de-DE', {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }) : ""}</Typography>
          )}
          {document.created_by && (
            <Typography>geschrieben von {document.created_by}</Typography>
          )}
          <Box>
            <IconButton sx={{ marginLeft: "10px" }}>
              <MilkdownProvider>
                <Editor body={document.description} /> /* TODO: ADD ID */
              </MilkdownProvider>
            </IconButton>
            {/*  TODO: Link stimmt so nicht */}
            <CopyToClipboard text={window.location.href}>
              <IconButton onClick={() => setOpenSnackbar(true)}>
                <LinkIcon />
              </IconButton>
            </CopyToClipboard>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleClose}
              message="Link in die Zwischenablage kopiert"
              action=""
            />
          </Box>
        </DateAndButtonBox>
        <ContentBox>
          {
            document.description &&
            <MilkdownProvider>
              <Editor body={document.description} />
            </MilkdownProvider>
          }
          {
            document.template_body &&
            <MilkdownProvider>
              <Editor body={document.template_body} />
            </MilkdownProvider>
          }
          <Aside>
            {document.images.length > 1 && (
              <>
                <Typography variant="h5" sx={{ margin: "20px" }}>
                  Bilder
                </Typography>
                {document.images.map((image, index) => {
                  // TODO: image link muss hier noch korrigiert werden
                  return (
                    <ImageModal
                      key={index}
                      image={image}
                      index={index}
                      stretch
                    />
                  );
                })}
              </>
            )}
            {document.images.length > 1 && document.files.length > 1 && (
              <Divider sx={{ color: theme.palette.darkgreen }} />
            )}
            {document.files.length > 1 && (
              <>
                <Typography variant="h5" sx={{ margin: "20px" }}>
                  Dateien
                </Typography>
                {document.files.map((file, index) => {
                  return (
                    <FileDownload key={index} href={`${url}${file.path}`}>
                      <DownloadIcon />
                      {file.name}
                    </FileDownload>
                  );
                })}
              </>
            )}
          </Aside>
        </ContentBox>
        <MainFacts>
          <InfoHeadline variant="h4"> Wichtige Infos zum Event:</InfoHeadline>
          <InnerFacts>
            {document.start_at && (
              <FlexBox>
                <AccessTimeOutlinedIcon />
                {document.end_at === document.start_at ? (
                  <Typography> am {document.start_at ? new Date(document.start_at).toLocaleDateString('de-DE', {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }) : ""}</Typography>
                ) : (
                  <>
                    <Typography>von {document.start_at ? new Date(document.start_at).toLocaleDateString('de-DE', {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }) : ""}</Typography>
                    {document.end_at && (
                      <Typography>bis {document.end_at ? new Date(document.end_at).toLocaleDateString('de-DE', {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }) : ""}</Typography>
                    )}
                  </>
                )}
              </FlexBox>
            )}
            {locations.length > 0 && (
              <FlexBox>
                <LocationOnOutlinedIcon />
                <Typography>{locations.map((e) => e)}</Typography>
              </FlexBox>
            )}
            {document.takealong && (
              <FlexBox>
                <LightbulbOutlinedIcon />
                <MilkdownProvider>
                  <Editor body={document.takealong} />
                </MilkdownProvider>
              </FlexBox>
            )}
          </InnerFacts>
        </MainFacts>
      </MainContent>
      {document.comments.length !== 0 && <Comments data={document.comments} />}
    </>
  );
}

export default Event;

const MainContent = styled(Box)(() => ({
  marginBottom: 80,
  [theme.breakpoints.up("md")]: {
    marginBottom: 100,
  },
}));

const StyledHeadline = styled(Box)(() => ({
  borderLeft: `${theme.palette.orange} 20px inset`,
  borderTop: `${theme.palette.white} 10px outset`,
  borderBottom: `${theme.palette.white} 10px inset`,
  background: `${theme.palette.orange}44`,
  padding: 20,
  marginLeft: 0,
  width: "85%",

  [theme.breakpoints.up("md")]: {
    width: "100%",
    marginLeft: "-20px",
    paddingLeft: 30,
  },
}));

const DateAndButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  alignItems: "center",
  padding: "10px 30px",
  margin: "20px 0 20px -16px",
  width: "90%",
  borderBottom: `${theme.palette.lightgreen} 3px solid`,

  [theme.breakpoints.up("md")]: {
    width: "90%",
    margin: "10px auto",
  },
}));

const InnerFacts = styled(Box)(() => ({
  paddingLeft: "20px",

  [theme.breakpoints.up("md")]: {
    paddingLeft: "60px",
  },
}));

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "top",
  gap: 20,
  marginTop: 10,
}));

const InfoHeadline = styled(Typography)(() => ({
  fontWeight: 600,
  padding: 20,
}));

const MainFacts = styled(Box)(() => ({
  wordBreak: "break-word",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "20px 10px 30px",
  margin: "20px 30px 20px -16px",
  background: theme.palette.yellow,
  width: "100vw",

  [theme.breakpoints.up("md")]: {
    padding: "20px 30px 30px",
    margin: "20px 0",
    width: "100%",
  },
  "& > div": {
    paddingRight: "20px",

    [theme.breakpoints.up("md")]: {
      paddingRight: "50px",
    },

    "& p, a": {
      maxWidth: "calc(100% - 10px)",
      margin: 0,
      padding: 0,
      hyphens: "auto",
      wordBreak: "break-word",
      textWrap: "wrap"
    }
  },
}));

const ContentBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const BlogText = styled(Box)(() => ({
  boxSizing: "border-box",
  width: "100%",
  padding: "0 20px 0 10px",
  textAlign: "left",
  fontFamily: "lora",

  [theme.breakpoints.up("md")]: {
    padding: 30,
    width: "70%",
  },

  "& p": {
    hyphens: "auto"
  }
}));

const FileDownload = styled("a")(() => ({
  color: theme.palette.black,
  display: "flex",
  alignItems: "center",
  gap: 20,
  background: theme.palette.lightgreen,
  padding: 20,
  marginBottom: 20,
  textDecorationColor: "transparent",
  transition: "text-decoration-color 350ms ease-in-out",
  wordBreak: "break-all",
  "& svg": {
    transform: "scale(1)",
    transition: "transform 350ms ease",
  },
  "&:hover": {
    transition: "text-decoration-color 350ms ease-in-out",
    textDecorationColor: theme.palette.black,
    "& svg": {
      transform: "scale(1.5)",
      transition: "transform 350ms ease",
    },
  },
}));

const Aside = styled(Box)(() => ({
  boxSizing: "border-box",
  width: "100%",
  padding: "0 25px 0 10px",
  [theme.breakpoints.up("md")]: {
    width: "30%",
    padding: "0 30px",
  },
}));
