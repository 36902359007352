import { useEffect, useState } from 'react'
import { collab, collabServiceCtx } from '@milkdown/plugin-collab';
import { Milkdown, useEditor } from '@milkdown/react';
import { ssl, domain } from '../../helper/websocketHelper';
import { Crepe } from '@milkdown/crepe';
import * as Y from 'yjs'
import { WebsocketProvider } from 'y-websocket'
import "@milkdown/crepe/theme/common/style.css";
import "@milkdown/crepe/theme/frame.css";
import "@milkdown/crepe/theme/common/style.css";

const Editor = ({ body, id }) => {
    const [yDoc, setYDoc] = useState(new Y.Doc());
    const { loading, get } = useEditor(
        (root) => {
            const crepe = new Crepe({
                root, features: {
                    [Crepe.Feature.CodeMirror]: false,
                    [Crepe.Feature.Toolbar]: true,
                },
                defaultValue: body
            });

            if (typeof id !== 'number') {
                console.log("Readonly mode");
                crepe.setReadonly(true);
            } else {
                console.log("Editor mode:", id);
                setYDoc(
                    new Y.Doc()
                );
                crepe.editor.use(collab);
                crepe.setReadonly(false);
            }

            return crepe
        }, [body, id]);
    useEffect(() => {
        if (loading) {
            console.log("Still loading editor");
            return;
        }

        if (typeof id !== 'number') {
            console.log("Readonly mode, not connecting to yws service");
            return;
        }

        const url = `ws${ssl ? "s" : ""}://${domain}/yws`;
        console.log("Editor mode, connecting to yws service at " + url);
        get().action((ctx) => {
            const collabService = ctx.get(collabServiceCtx);
            const provider = new WebsocketProvider(url, id, yDoc)

            collabService
                .bindDoc(yDoc)
                .setAwareness(provider.awareness);

            provider.once('synced', (isSynced) => {
                if (isSynced) {
                    collabService
                        .applyTemplate(body)
                        .connect();
                }
            });
        });
    }, [loading, body, id, yDoc]);

    return (
        <Milkdown />
    );
};

export default Editor;
