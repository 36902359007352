import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";
import BlogText from "./modules/BlogText";

function News({ data }) {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Styles
  const Wrapper = styled(Container)(() => ({
    paddingBottom: 30,
    margin: "10px 0 50px",
    //borderBottom: `solid ${theme.palette.darkgreen} 3px`,
    [theme.breakpoints.up("md")]: {
      border: "none",
      margin: "25px 0",
    },

    "& .headlineLink": {
      textDecoration: "none",

      "&:hover > h2": {
        backgroundPosition: "0%",
      },
    },
  }));

  const StyledDate = styled(Typography)(() => ({
    margin: "-20px 0 20px",
    textAlign: "right",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      margin: "10px 22px 0",
    },
  }));

  const StyledHeadline = styled(Typography)(() => ({
    fontSize: 20,
    borderLeft: `${theme.palette.darkgreen} 20px inset`,
    borderTop: `${theme.palette.white} 10px outset`,
    borderBottom: `${theme.palette.white} 10px inset`,
    background: `linear-gradient(90deg, ${theme.palette.lightgreen}bb 0%, ${theme.palette.lightgreen}bb 49%, ${theme.palette.lightgreen}44 51%, ${theme.palette.lightgreen}44 100%)`,
    backgroundSize: "200%",
    backgroundPosition: "100%",
    padding: "20px 40px 20px 20px",
    marginLeft: "-20px",
    width: "95%",
    transition: "background-position 100ms ease-out",
    marginBottom: 0,
    whiteSpace: "break-spaces",

    [theme.breakpoints.up("md")]: {
      width: "100%",
      paddingLeft: 30,
      marginLeft: 0,
    },
  }));

  const ButtonBox = styled(Box)(() => ({
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    flexDirection: "row-reverse",
    [theme.breakpoints.up("md")]: {
      gap: 30,
      padding: "0px 30px",
    },
  }));

  return (
    <>
      <Typography variant="h1">News</Typography>
      {data.interaction.news.data.rows
        ? data.interaction.news.data.rows.map((e, index) => {
            return (
              <Wrapper key={index}>
                <Link
                  className="headlineLink"
                  to={e.interaction.document.value.link}
                  onClick={goToTop}
                >
                  <StyledHeadline variant="h2">
                    {e.interaction.document.value.title}
                  </StyledHeadline>
                </Link>
                <ButtonBox>
                  <StyledDate>{new Date(e.interaction.document.value.created_at).toLocaleDateString('de-DE', {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
                </StyledDate>
                </ButtonBox>
                <BlogText
                  data={e.interaction.document.value.body}
                  image={e.interaction.document.value.images[0]}
                />
              </Wrapper>
            );
          })
        : "keine News"}
    </>
  );
}

export default News;
