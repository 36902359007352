import { useState } from "react";
import { Box, Dialog, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../../theme";
import { url } from "../../helper/websocketHelper";
import CloseIcon from "@mui/icons-material/Close";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

function ImageModal({ image, index, maxHeight, stretch, className }) {
  const [imageOpen, setImageOpen] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(false);

  // rotate image button
  let angle = [0, 90, 180, 270];

  const rotateRight = () => {
    setRotation(rotation + 1);
    if (rotation === 4) setRotation(0);
  };

  const handleClose = () => {
    setImageOpen(false);
    setRotation(0);
    setZoom(false);
  };

  const ImagePreview = styled("img")(() => ({
    backgroundSize: "contain",
    height: "auto",
    width: stretch ? "100%" : "unset",
    maxHeight: maxHeight ? maxHeight : "unset",
    backgroundRepeat: "no-repeat",
    padding: "0 0 30px 0",
    cursor: "zoom-in",
    transform: "scale(1)",
    transition: "transform 250ms ease-in-out",
    "&:hover": {
      transform: "scale(1.03)",
      transition: "transform 250ms ease-in-out",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 0 30px 0",
    },
  }));

  return (
    <Box>
      <ImagePreview
        src={`${url}${image}`}
        onClick={() => setImageOpen(true)}
        className={className}
      />
      <Dialog
        open={imageOpen}
        onClose={handleClose}
        fullScreen={zoom}
        maxWidth={zoom ? false : "lg"}
      >
        <ButtonBox>
          <StyledButton onClick={() => setZoom(!zoom)}>
            {zoom ? <ZoomOutIcon /> : <ZoomInIcon />}
          </StyledButton>
          <StyledButton onClick={rotateRight}>
            <RotateRightIcon />
          </StyledButton>
          <StyledButton onClick={() => setImageOpen(false)}>
            <CloseIcon />
          </StyledButton>
        </ButtonBox>
        <ModalBox
          sx={
            rotation !== 0
              ? { transform: `rotate(${angle[rotation]}deg)` }
              : { transform: "rotate(0)" }
          }
        >
          <ImageInModal src={`${url}${image}`} alt={`image-${index}`} />
        </ModalBox>
      </Dialog>
    </Box>
  );
}

export default ImageModal;

const ModalBox = styled(Box)(() => ({
  background: theme.palette.black,
}));

const ImageInModal = styled("img")(() => ({
  height: "100vh",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    height: "auto",
  },
}));

const ButtonBox = styled(Box)(() => ({
  position: "fixed",
  zIndex: 5,
  top: 20,
  right: 0,
  width: "100vw",
  display: "flex",
  justifyContent: "space-evenly",
  gap: 20,
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    width: 200,
    right: 20,
  },
}));

const StyledButton = styled(IconButton)(() => ({
  color: theme.palette.black,
  background: `${theme.palette.white}ff`,
  transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "transform 150ms ease-in-out, background 350ms ease-in-out",
    background: `${theme.palette.lightgreen}ff`,
  },
}));
