import { Box, Button, Select, TextField, FormControl, InputLabel, MenuItem } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { useState } from "react";
import styled from "@emotion/styled";
import { sendToBackend } from "../helper/websocketHelper";

function User({ data }) {
  const [error, setError] = useState(false);

  const texts = data.interaction.texts.value;
  const Wrapper = styled(Box)(() => ({

    ".flexcontainer": {
      display: "flex",
      padding: "0 20px",
      justifyContent:"space-evenly",
      gap: 20,
    },

    ".column": {
      flex: "1 1 100%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      gap: 20,

      "h4": {
        textAlign: "center"
      }
    },

    ".select": {
      width: "100%",
      minWidth: 200,
    }, 

    ".error": {
      display: "flex",
      gap: 5,
      alignItems: "center",
      color: "red"
    }
  }));

  return (
    <Wrapper className="membership">
      <h2>{texts.change_profile_header}</h2>
      <div className="flexcontainer">
        <div className="column">
          <h4>{texts.user}</h4>
            <p>Name: {data.interaction.username.value}</p>
            <p>Email: {data.interaction.email.value}</p>
{/*           <h4>Email-Adresse ändern</h4>
          <TextField 
            id="change_email"
            label="deine Email-Adresse"
            variant="outlined"
            placeholder={data.interaction.email.value}
          />
          <Button variant="contained" onClick={()=> {
            const newEmail = document.querySelector("#change_email");
            sendToBackend("email", {value: newEmail.value})
          }}>
            Email-Adresse ändern
          </Button> */}
        </div>

        <div className="column">
        <h4>{texts.change_language}</h4>
        <FormControl fullWidth>
          <InputLabel id="languageswitch-label">{texts.language}</InputLabel>
          <Select
            labelId="languageswitch-label"
            id="languageswitch"
            value={data.interaction.language.value}
            label={texts.language}
            className="select"
            onChange={(event) => sendToBackend("language", {value: event.target.value})}
          >
            <MenuItem value={"German"}>deutsch</MenuItem>
            <MenuItem value={"English"}>english</MenuItem>
          </Select>
        </FormControl>
        </div>
        <div className="column">
        <h4>{texts.change_password}</h4>
          <TextField
            id="new_pw_1"
            label={texts.password}
            variant="outlined"
            type="password"
            fullWidth
          />
          <TextField
            id="new_pw_2"
            label={texts.password2}
            variant="outlined"
            type="password"
            fullWidth
          />
          {error &&
            <div className="error">
              <ErrorIcon /> 
              <p>{texts.passwords_not_matching}</p>
            </div>
          }
          <Button variant="contained" onClick={() => {
            const newPw1 = document.getElementById("new_pw_1");
            const newPw2 = document.getElementById("new_pw_2");

            if (newPw1.value === newPw2.value) {
              sendToBackend("password", {value: newPw1.value});
              setError(false);
            } else {
              setError(true);
            }
          }}>
            {texts.change_password}
          </Button>
        </div>
        </div>
    </Wrapper>
    )
}

export default User;