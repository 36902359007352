import theme from "../theme";
import styled from "@emotion/styled";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import { sendToBackend } from "../helper/websocketHelper";

function Footer({ staticTexts }) {
  return (
    <Wrapper>
      <Container>
        <Grid container>
          <GridItem item xs={12} md={6}>
            <Typography sx={{ width: "100%", flexGrow: 1 }} variant="h5">
              GartenCoop Freiburg
            </Typography>
            <Field>
              <FooterHeadline variant="h6">
                {staticTexts.mailing_address}
              </FooterHeadline>
              <FooterText>{staticTexts.mailing_address_line_0}</FooterText>
              <FooterText>{staticTexts.mailing_address_line_1}</FooterText>
              <FooterText>{staticTexts.mailing_address_line_2}</FooterText>
              <FooterHeadline variant="h6">{staticTexts.farmstead}</FooterHeadline>
              <FooterText>{staticTexts.farmstead_line_0}</FooterText>
              <FooterText>{staticTexts.farmstead_line_1}</FooterText>
              <FooterText>{staticTexts.farmstead_line_2}</FooterText>
            </Field>
            <Field>
              <FooterHeadline variant="h6">
                {staticTexts.bank_details}
              </FooterHeadline>
              <FooterText>{staticTexts.bank_details_line_0}</FooterText>
              <FooterText>{staticTexts.bank_details_line_1}</FooterText>
              <FooterText>{staticTexts.bank_details_line_2}</FooterText>
              <FooterHeadline variant="h6">{staticTexts.contact}</FooterHeadline>
              <FooterText>{staticTexts.contact_line_0}</FooterText>
              <FooterText>{staticTexts.contact_line_1}</FooterText>
            </Field>
          </GridItem>
          <Form item xs={12} md={6}>
            <Typography sx={{ width: "100%" }} variant="h5">
              {staticTexts.contact_form_title}
            </Typography>
            <StyledTextfield
              id="contactFormName"
              label={staticTexts.contact_form_name}
              variant="filled"
            />
            <StyledTextfield
              id="contactFormEmail"
              label={staticTexts.contact_form_email}
              variant="filled"
            />
            <StyledTextfield
              id="contactFormMessage"
              label={staticTexts.contact_form_message}
              variant="filled"
              multiline
              rows="3"
            />
            <SendButton variant="contained" onClick={() => {
              const name = document.getElementById("contactFormName");
              const email = document.getElementById("contactFormEmail");
              const message = document.getElementById("contactFormMessage");

              if (message.value.length > 0) {
                let payload = {
                  name: name.value,
                  email: email.value,
                  message: message.value,
                };
                name.value = "";
                email.value = "";
                message.value = "";

                sendToBackend("contact", payload);
              }
            }}>
              {staticTexts.contact_form_send}
            </SendButton>
          </Form>
          <BottomLine item xs={12}>
            {/*<Typography variant="h6">GartenCoop Freiburg</Typography>*/} 
            <StyledLink to="/Impressum">
              <Typography variant="h6">{staticTexts.impress}</Typography>
            </StyledLink>
            <StyledLink to="/Datenschutz">
              <Typography variant="h6">{staticTexts.privacy}</Typography>
            </StyledLink>
          </BottomLine>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Footer;

// Styling

const FooterHeadline = styled(Typography)(() => ({
  margin: "20px 0 5px",
}));

const FooterText = styled(Typography)(() => ({
  fontFamily: "lora",
  fontSize: "0.9rem",
}));

const Wrapper = styled(Box)(() => ({
  position: "relative",
  marginTop: 50,
  padding: 30,
  background: theme.palette.lightgreen,
  [theme.breakpoints.up("md")]: {
    padding: 80,
  },
}));

const GridItem = styled(Grid)(() => ({
  display: "flex",
  flexWrap: "wrap",
  [theme.breakpoints.up("md")]: {
    paddingRight: 10,
  },
}));

const Form = styled(Grid)(() => ({
  paddingTop: 30,
  [theme.breakpoints.up("md")]: {
    paddingTop: 0,
  },
}));

const Field = styled(Box)(() => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));

const StyledTextfield = styled(TextField)(() => ({
  margin: "2px 0",
  width: "100%",
  fontFamily: "Lora",
  "&:first-of-type": {
    marginRight: 10,
  },
  [theme.breakpoints.up("md")]: {
    margin: "10px 0",
    width: "calc(50% - 5px)",
    "&:last-of-type": {
      width: "100%",
    },
  },
}));

const SendButton = styled(Button)(() => ({
  margin: "10px 0 0 60%",
  [theme.breakpoints.up("md")]: {
    margin: "10px 0 0 83%",
  },
}));

const BottomLine = styled(Grid)(() => ({
  display: "flex",
  gap: 20,
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 50,
}));

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
}));
