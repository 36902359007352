import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../theme";

function HtmlText({ data }) {
  return (
    <>
      <StyledHeadline variant="h3">
        {data.interaction.title.value}
      </StyledHeadline>
      <Box
        dangerouslySetInnerHTML={{ __html: data.interaction.text.value }}
      ></Box>
    </>
  );
}

export default HtmlText;

const StyledHeadline = styled(Typography)(() => ({
  borderLeft: `${theme.palette.yellow} 20px inset`,
  borderTop: `${theme.palette.white} 10px outset`,
  borderBottom: `${theme.palette.white} 10px inset`,
  background: `${theme.palette.yellow}44`,
  padding: 20,
  marginLeft: 0,
  width: "85%",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    marginLeft: "-20px",
    paddingLeft: 30,
  },
}));
